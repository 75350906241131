<template>
  <div class="custom-actions">
    <b-button class="btn btn-sm" @click="itemAction('download', rowData, rowIndex)" :disabled="downloadDisabled(rowData)" :hidden="downloadDisabled(rowData)" v-b-tooltip.hover title="scarica i file"><i class="glyphicon glyphicon-download-alt"></i></b-button>
    <b-button class="btn btn-sm" @click="itemAction('toPay', rowData, rowIndex)" :disabled="topayDisabled(rowData)" :hidden="topayDisabled(rowData)" v-b-tooltip.hover title="crea il link di pagamento"><i class="glyphicon glyphicon-euro"></i></b-button>
    <b-button class="btn btn-sm" @click="itemAction('toRefund', rowData, rowIndex)" :disabled="torefundDisabled(rowData)" :hidden="torefundDisabled(rowData)" v-b-tooltip.hover title="rimborsa pagamento"><i class="glyphicon glyphicon-euro"></i></b-button>
    <b-button class="btn btn-sm" @click="itemAction('validate', rowData, rowIndex)" :disabled="validateDisabled(rowData)" :hidden="validateDisabled(rowData)"  v-b-tooltip.hover title="setta come PRONTA"><i class="glyphicon glyphicon-ok"></i></b-button>
    <b-button class="btn btn-sm" @click="itemAction('toHand', rowData, rowIndex)" :disabled="handDisabled(rowData)" :hidden="handDisabled(rowData)"  v-b-tooltip.hover title="setta come CONSEGNATA"><i class="glyphicon glyphicon-tag"></i></b-button>
    <b-button class="btn btn-sm" @click="itemAction('reject', rowData, rowIndex)" :disabled="rejectDisabled(rowData)" :hidden="rejectDisabled(rowData)" v-b-tooltip.hover title="setta come RIFIUTATA"><i class="glyphicon glyphicon-trash"></i></b-button>
    <a v-bind:href="`mailto:${rowData.email}?subject=Abbonamenti - Numero pratica ${rowData.number}`">
      <b-button class="btn btn-sm" @click="itemAction('mailto', rowData, rowIndex)" v-b-tooltip.hover title="invia una mail" :hidden="!Constants.writePermission" ><i class="glyphicon glyphicon-envelope"></i></b-button>
    </a>
    <!-- <a v-bind:href="`mailto:${rowData.email}`" class="glyphicon glyphicon-envelope"></a> -->
  </div>
</template>

<script>
  import axios from 'axios'
  import Vue from "vue"  
  import VuejsDialog from "vuejs-dialog"
  Vue.use(VuejsDialog)

  import Constants from './Constants.vue'

  export default {

    props: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return { 
        Constants, 
        muvinws_base_url: Constants.muvinws_base_url,
      }
    },
    methods: {
      downloadDossierDocs(number, state, cost, online_payment, index) {
          const FileDownload = require('js-file-download');
          // console.log('Donwload docs' + number);
          this.$events.fire('show-loading');
          axios({
            url: this.muvinws_base_url + '/dossierfiles/' + number,
            method: 'PUT',
            responseType: 'blob', // Important
            data: {
              user_id: Constants.user_id,
            }
          }).then((response) => {
              
              if (response.status != 200) {  
                alert('Documenti non presenti.');
              } else {
                FileDownload(response.data, 'pratica_numero_'+number+'.zip');
                console.log('dossier-working '+ cost + ' - ' + online_payment)
                if (state == 'in_attesa') {
                  // if (parseFloat(cost) > 0)
                    this.$events.fire('dossier-working', index, 'in_lavorazione');
                  // else if (parseFloat(cost) == 0 && online_payment)
                  //   this.$events.fire('dossier-working', index, 'pagata');
                }
                this.$events.fire('hide-loading');
              }
              
          },(error) => {
            console.log(error);
            this.$events.fire('hide-loading');
            alert(error.response.data.messages);
          });
      },
      validateDossierDefault(number, index) {
          // console.log('Validate ' + number);
          let confirmok = prompt("Inserire il numero del contrassegno emesso:")
          // console.log("Eliminazione " + confirmok)

          if (confirmok) {
            this.$events.fire('show-loading');
            axios({
              url: this.muvinws_base_url + '/dossiervalidate/' + number,
              method: 'PUT',
              data: {
                pass: confirmok,
                user_id: Constants.user_id,
              }
            }).then((response) => {
                
                this.$events.fire('hide-loading');

                if (response.status != 200) {  
                  alert('Pratica non presente.');
                } else {
                  if (response.data.errors)
                    alert(response.data.messages)
                  else
                    this.$events.fire('dossier-validate', index, confirmok);
                }
            }).catch((error) => {
              console.log(error.response.data.messages);
              this.$events.fire('hide-loading');
              alert(error.response.data.messages);
            });
          }
      },
      validateDossierSetDates(number, index) {
        this.$events.fire('show-modal', number, index);
      },
      validateDossier(number, index) {
          if (Constants.set_validity_date)
            this.validateDossierSetDates(number, index)
          else
            this.validateDossierDefault(number, index)
      },
      incompleteDossier(number, index) {
          // console.log('Incomplete ' + number + " " + index );
          
          let confirmok = prompt("La pratica numero " + number + " è incompleta? Motivazione:")
          // console.log("Eliminazione " + confirmok)

          if (confirmok) {
            this.$events.fire('show-loading');
            axios({
              url: this.muvinws_base_url + '/dossierincomplete/' + number,
              method: 'PUT',
              data: {
                note: confirmok,
                user_id: Constants.user_id,
              }
            }).then((response) => {
                this.$events.fire('hide-loading');

                if (response.status != 200) {  
                  alert('Pratica non presente.');
                } else {
                  this.$events.fire('dossier-incomplete', index);
                }
                
            },(error) => {
              console.log(error);
              this.$events.fire('hide-loading');
            });
          }
      },
      rejectDossier(number, index) {
          // console.log('Reject ' + number + " " + index );
          
          let confirmok = prompt("Vuoi respingere la pratica numero " + number + "? Motivazione:")
          // console.log("Eliminazione " + confirmok)

          if (confirmok) {
            this.$events.fire('show-loading');
            axios({
              url: this.muvinws_base_url + '/dossierreject/' + number,
              method: 'PUT',
              data: {
                note: confirmok,
                user_id: Constants.user_id,
              }
            }).then((response) => {
                this.$events.fire('hide-loading');

                if (response.status != 200) {  
                  alert('Pratica non presente.');
                } else {
                  this.$events.fire('dossier-reject', index);
                }
                
            },(error) => {
              console.log(error);
              this.$events.fire('hide-loading');
            });
          }
      },
      topayDossier(number, index) {
          // console.log('Reject ' + number + " " + index );
          
          let confirmok = confirm("Vuoi creare il link di pagamento per la pratica numero " + number + "?")
          // console.log("Eliminazione " + confirmok)

          if (confirmok) {
            this.$events.fire('show-loading');
            axios({
              url: this.muvinws_base_url + '/dossiertopay/' + number,
              method: 'PUT',
              data: {
                note: confirmok,
                user_id: Constants.user_id,
              }
            }).then((response) => {
                this.$events.fire('hide-loading');

                if (response.status != 200) {  
                  alert('Pratica non presente.');
                } else {
                  this.$events.fire('dossier-topay', index);
                }
                
            },(error) => {
              console.log(error);
              this.$events.fire('hide-loading');
            });
          }
      },
      tohandDossier(number, index) {
          // console.log('Reject ' + number + " " + index );
          
          let confirmok = confirm("Confermi la consegna della pratica " + number + "?")
          // console.log("Eliminazione " + confirmok)

          if (confirmok) {
            this.$events.fire('show-loading');
            axios({
              url: this.muvinws_base_url + '/dossiertohand/' + number,
              method: 'PUT',
              data: {
                note: confirmok,
                user_id: Constants.user_id,
              }
            }).then((response) => {
                this.$events.fire('hide-loading');

                if (response.status != 200) {  
                  alert('Pratica non presente.');
                } else {
                  this.$events.fire('dossier-tohand', index);
                }
                
            },(error) => {
              console.log(error);
              this.$events.fire('hide-loading');
            });
          }
      },
      torefundDossier(number, index) {
          // console.log('Reject ' + number + " " + index );
          
          let confirmok = prompt("Vuoi rimborsare la pratica con numero ordine " + number + "? Motivazione:")
          // console.log("Eliminazione " + confirmok)

          if (confirmok) {
            this.$events.fire('show-loading');
            axios({
              url: this.muvinws_base_url + '/refund',
              method: 'POST',
              data: {
                reason: confirmok,
                uuid: number
              }
            }).then((response) => {
                this.$events.fire('hide-loading');

                if (response.status != 200) {  
                  alert('Pratica non presente.');
                } else {
                  this.$events.fire('dossier-torefund', index);
                }
                
            },(error) => {
              console.log(error);
              this.$events.fire('hide-loading');
            });
          }
      },
      downloadDisabled (data) {
        if (data.city_id == 7 && ((data.renewal && data.cost == 0) || (data.renewal && data.online_payment)))
          return true
        
        return false
      },
      validateDisabled (data) {
        if (Constants.userLevel == 'ospite' || data.state == 'consegnata' ) return true

        // if (((data.state == 'in_lavorazione' || data.state == 'incompleta') && !data.online_payment) || (data.online_payment && data.cost == 0) || ( data.state == 'pagata'))
        if (((data.state == 'in_lavorazione' || data.state == 'incompleta') && !data.online_payment) || ( data.state == 'pagata'))
          return false
        if (data.state == 'in_lavorazione' && data.cost == 0)
          return false
        
        return true
      },
      handDisabled (data) {
        if (Constants.userLevel == 'ospite') return true

        if ( data.state == 'pronta' )
          return false
        
        return true
      },
      topayDisabled (data) {        
        if (Constants.userLevel == 'ospite' || Constants.userLevel == 'finalizzatore' || data.cost == 0) return true

        if ((data.state == 'in_lavorazione' || data.state == 'incompleta' || data.state == 'da_pagare') && data.online_payment)
          return false
        
        return true
      },
      torefundDisabled (data) {
        if (Constants.userLevel != 'admin' || data.cost == 0) return true

        if ((data.state == 'pagata' || data.state == 'pronta' || data.state == 'consegnata') && data.online_payment)
          return false
        
        return true
      },
      // incompleteDisabled (data) {
      //   // if (Constants.writePermission == 0 && Constants.userLevel == 'ospite') return true

      //   // if (data.state == 'in_lavorazione')
      //   //   return false
        
      //   return true
      // },
      rejectDisabled (data) {
        if (Constants.userLevel == 'finalizzatore') return true

        if (data.state == 'in_lavorazione' || data.state == 'incompleta' || data.state == 'da_pagare')
          return false
        
        return true
      },

      
      itemAction (action, data, index) {
        // console.log('custom-actions: ' + action, data.id, data.email, index)
        switch(action) {
          case 'download':
              this.downloadDossierDocs(data.number, data.state, data.cost, data.online_payment, index);
              break;
          case 'validate':
              this.validateDossier(data.number, index);
              break;
          case 'incomplete':
              this.incompleteDossier(data.number, index);
              break;
          case 'reject':
              this.rejectDossier(data.number, index);
              break;
          case 'toPay':
              this.topayDossier(data.number, index);
              break;
          case 'toHand':
              this.tohandDossier(data.number, index);
              break;
          case 'toRefund':
              this.torefundDossier(data.op_reference, index);
              break;
          
        }
      }
    }
  }
</script>

<style>
  .custom-actions button.ui.button {
    padding: 8px 8px;
  }
  .custom-actions button.ui.button > i.icon {
    margin: auto !important;
  }
  .btn-secondary {
    color: black;
    background-color: transparent;
  }
  .btn-secondary:focus, .btn-secondary.focus {
    /* color: #fff; */
    background-color: transparent;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
  }
</style>
