<template>
  <b-nav-item-dropdown v-model="selectCity" text="Città">
    <b-dropdown-item v-for="city in cities" :key="city.id" @click="citySelected(city.id, city.name, city.set_validity_date)" >{{ city.name }}</b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'

  import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

  import 'bootstrap/dist/css/bootstrap.css'
  import 'bootstrap-vue/dist/bootstrap-vue.css'
  Vue.use(BootstrapVue)
  Vue.use(IconsPlugin)

  import Constants from './Constants'

  export default {
      name: 'Dossiers',
      data() {
          return {
              Constants,
              muvinws_base_url: Constants.muvinws_base_url,
              cities: null,
              selectCity: '',
              url_city_selected: '#',
              city_name: '',
          };
      },
      created: function() {
          // console.log(Constants.user_id)
          axios
              .get(this.muvinws_base_url + '/cities/' + Constants.user_id)
              .then(res => {
                  this.cities = res.data;
                  if (this.cities.length == 1) {
                    let selectedCity = this.cities[0]
                    this.citySelected(selectedCity.id, selectedCity.name, selectedCity.set_validity_date)
                  }
                  console.log('Citta', this.cities)
              })
      },
      methods: {
        citySelected(id, name, set_validity_date) {
          // console.log('Città selezionata ' +  id + ' ' + set_validity_date)
          Constants.city_id = id
          Constants.set_validity_date = set_validity_date
          this.url_city_selected = this.muvinws_base_url + '/dossiers/'+ id
          this.city_name = name
          this.$events.fire('city-set', this.url_city_selected)
          this.$events.fire('city-name-set', this.city_name)
          this.$events.fire('filterbar-default')
          
        }
      }
  }
    
</script>

<style>
  h3 {
    margin-bottom: 5%;
  }
</style>
