import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginComponent from "../views/login.vue"
import DossiersDataTable from "../components/DossiersDataTable.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
        name: "login"
    }
  },
  {
      path: "/login",
      name: "login",
      component: LoginComponent
  },
  {
    path: '/',
    name: 'dossiersDataTable',
    component: DossiersDataTable
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
