<template>
    <div id="app" style="width: 95%;margin-left: 2%;">
        <Loading />
        <div id="nav" style="text-align: end;">
            <span>{{ Constants.username }}</span>
            <router-link v-if="authenticated" to="/login" v-on:click.native="logout()" replace>Logout</router-link>
        </div>
        <router-view @authenticated="setAuthenticated" />
    </div>
</template>

<script>
    import Constants from './components/Constants.vue'
    import Loading from './components/Loading.vue'

    export default {
        name: 'App',
        data() {
            return {
                Constants,
                authenticated: false,
                citiesList: [],
                mockAccount: {
                    username: "nraboy",
                    password: "password"
                }
            }
        },
        mounted() {
            // if(!this.authenticated) {
            //     this.$router.replace({ name: "login" });
            // }
        },
        components: {
            Loading
        },
        methods: {
            setAuthenticated(status) {
                this.authenticated = status;
            },
            logout() {
                Constants.username = '';
                this.authenticated = false;
            }
        },
    }
</script>

<style>
    body {
        background-color: #F0F0F0;
    }
    h1 {
        padding: 0;
        margin-top: 0;
    }
    #app {
        width: 1024px;
        margin: auto;
    }
</style>