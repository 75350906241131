<template>
  <div>
    <filter-bar></filter-bar>
    <vuetable ref="vuetable"
      :api-url="url_city"
      :fields="fields"
      pagination-path=""
      :css="css.table"
      :sort-order="sortOrder"
      :multi-sort="true"
      detail-row-component="my-detail-row"
      :append-params="moreParams"
      @vuetable:cell-clicked="onCellClicked"
      @vuetable:pagination-data="onPaginationData"
    >
      <!-- <div slot="state-slot" slot-scope="props">
        <span :style="{color: Constants.dossierStatus[props.rowData.state].color}" >{{ Constants.dossierStatus[props.rowData.state].name }}</span>
      </div> -->
    </vuetable>
    <div class="vuetable-pagination">
      <vuetable-pagination-info ref="paginationInfo"
        info-class="pagination-info"
      ></vuetable-pagination-info>
      <vuetable-pagination ref="pagination"
        :css="css.pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import accounting from 'accounting'
import moment from 'moment'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import Vue from 'vue'
import VueEvents from 'vue-events'
import CustomActions from './CustomActions'
import CustomState from './CustomState'
import DetailRow from './DetailRow'
import FilterBar from './FilterBar'

import Constants from './Constants.vue'
import Cities from './Cities.vue'

Vue.use(VueEvents)
Vue.component('custom-actions', CustomActions)
Vue.component('custom-state', CustomState)
Vue.component('my-detail-row', DetailRow)
Vue.component('filter-bar', FilterBar)

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  Cities,
  FilterBar,
  data () {
    return {
      Constants,
      url_city: '#',
      currentData: {
        currentPage: '1'
      },
      fields: [
        {
          name: 'number',
          sortField: 'number',
          title: 'Numero Pratica',
          titleClass: 'text-center',
          dataClass: 'text-right'
        },
        {
          name: 'op_reference',
          sortField: 'op_reference',
          title: '# ordine',
          titleClass: 'text-center',
          dataClass: 'text-right'
        },
        {
          name: 'subscription',
          sortField: 'subscription',
          title: 'Abbonamento',
        },
        {
          name: 'renewal',
          sortField: 'renewal',
          title: 'Rinnovo',
          callback: 'formatRenewal',
        },
        {
          name: 'surname',
          sortField: 'surname',
          title: 'Cognome',
        },
        {
          name: 'name',
          sortField: 'name',
          title: 'Nome',
        },
        {
          name: 'company',
          sortField: 'company',
          title: 'Rag. Sociale',
        },
        {
          name: 'licences',
          sortField: 'licences',
          callback: 'formatLicences',
          title: 'Targhe',
        },
        {
          name: 'created_at',
          sortField: 'created_at',
          titleClass: 'text-center',
          dataClass: 'text-center',
          callback: 'formatDate|DD-MM-YYYY',
          title: 'Data creazione'
        },
        {
          //name: 'state',
          name: '__component:custom-state',
          sortField: 'state',
          title: 'Stato'
        },
        {
          name: 'online_payment',
          sortField: 'online_payment',
          title: 'Pagamento Online',
          callback: 'formatOnlinePayment'
        },
        {
          name: 'cost',
          sortField: 'cost',
          title: 'Costo',
        },
        {
          //name: 'state',
          name: 'pass',
          sortField: 'pass',
          title: 'Contrassegno',
          // visible: Constants.set_validity_date
        },
        {
          name: '__component:custom-actions',
          title: '',
          titleClass: 'text-center',
          dataClass: 'text-center'
        }
      ],
      css: {
        table: {
          tableClass: 'table table-bordered table-striped table-hover',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down'
        },
        pagination: {
          wrapperClass: 'pagination',
          activeClass: 'active',
          disabledClass: 'disabled',
          pageClass: 'page',
          linkClass: 'link',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        },
        icons: {
          first: 'glyphicon glyphicon-step-backward',
          prev: 'glyphicon glyphicon-chevron-left',
          next: 'glyphicon glyphicon-chevron-right',
          last: 'glyphicon glyphicon-step-forward',
        },
      },
      sortOrder: [
        { field: 'number', direction: 'asc'},
      ],
      moreParams: {}
    }
  },
  methods: {
    // getSortParam(sortOrder) {
    //   return sortOrder.map(function(sort) {
    //     return (sort.direction === 'desc' ? '-' : '') + sort.field
    //   }).join(',')
    // },
    formatLicences (value) {
      return value.split('|').join(' - ')
    },
    formatRenewal (value) {
      if (value == 0)
        return "NO"
      else
        return "SI"
    },
    formatOnlinePayment (value) {
      if (value == 0)
        return "NO"
      else
        return "SI"
    },
    allcap (value) {
      return value.toUpperCase()
    },
    formatNumber (value) {
      return accounting.formatNumber(value, 2)
    },
    formatDate (value, fmt = 'D MMM YYYY') {
      return (value == null)
        ? ''
        : moment(value, 'YYYY-MM-DD').format(fmt)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.currentData.currentPage = page
      this.$refs.vuetable.changePage(page)
    },
    onCellClicked (data) {
      // console.log('cellClicked: ', field.name)
      this.$refs.vuetable.toggleDetailRow(data.id)
    },
  },
  events: {
    'city-set' (url_city_selected) {
      // console.log('Url_city passing: ' + url_city_selected)
      this.url_city = url_city_selected
      this.moreParams = {
        filter: "state|"+Constants.dossierStateDefault
      }
      //Vue.nextTick( () => this.$refs.vuetable.refresh() )
    },
    'filter-set' (filterText, isdefault) {
      console.log('Filter passing: ' + filterText)
      this.moreParams = {
        filter: filterText
      }
      if(!isdefault)
        Vue.nextTick( () => this.$refs.vuetable.refresh() )
    },
    'filter-reset' () {
      this.moreParams = {}
      Vue.nextTick( () => this.$refs.vuetable.refresh() )
    },
    'dossier-working' (index, state) {
      // console.log(this.$refs.vuetable)
      Vue.nextTick( () => { this.$refs.vuetable.tableData[index].state = state; })
      
    },
    'dossier-validate' (index, pass) {
      // console.log(this.$refs.vuetable)
      Vue.nextTick( () => { 
        this.$refs.vuetable.tableData[index].state = 'pronta'; 
        this.$refs.vuetable.tableData[index].pass = pass;
      })
      
    },
    'dossier-validate-detail' (index, start_date, end_date, badge) {
        console.log(this.$refs.vuetable)
        Vue.nextTick( () => { 
          this.$refs.vuetable.tableData[index].start_date = start_date; 
          this.$refs.vuetable.tableData[index].end_date = end_date;
          this.$refs.vuetable.tableData[index].badge = badge;
        })
    },
    'dossier-incomplete' (index) {
      // console.log(this.$refs.vuetable)
      Vue.nextTick( () => { this.$refs.vuetable.tableData[index].state = 'incompleta'; })
      
    },
    'dossier-reject' (index) {
      // console.log(this.$refs.vuetable)
      Vue.nextTick( () => { this.$refs.vuetable.tableData[index].state = 'rifiutata'; })
      
    },
    'dossier-topay' (index) {
      // console.log(this.$refs.vuetable)
      Vue.nextTick( () => { this.$refs.vuetable.tableData[index].state = 'da_pagare'; })
      
    },
    'dossier-tohand' (index) {
      // console.log(this.$refs.vuetable)
      Vue.nextTick( () => { this.$refs.vuetable.tableData[index].state = 'consegnata'; })
      
    },
    'dossier-torefund' (index) {
      // console.log(this.$refs.vuetable)
      Vue.nextTick( () => { this.$refs.vuetable.tableData[index].state = 'rimborsata'; })
      
    }
  }
}
</script>
<style>
.pagination {
  margin: 0;
  float: right;
}
.pagination a.page {
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 2px;
}
.pagination a.page.active {
  color: white;
  background-color: #337ab7;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 2px;
}
.pagination a.btn-nav {
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 7px;
  margin-right: 2px;
}
.pagination a.btn-nav.disabled {
  color: lightgray;
  border: 1px solid lightgray;
  border-radius: 3px;
  padding: 5px 7px;
  margin-right: 2px;
  cursor: not-allowed;
}
.pagination-info {
  float: left;
}
</style>
