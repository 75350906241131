<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
        loader="dots"
        color="#007bff"
        :is-full-page="fullPage"></loading>
    </div>
</template>

<script>
    import Vue from 'vue'
    import VueEvents from 'vue-events'

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    
    Vue.use(VueEvents)

    export default {
        data() {
            return {
                isLoading: false,
                fullPage: true
            }
        },
        components: {
            Loading
        },
        events: {
            'show-loading' () {
                Vue.nextTick( () => {  this.isLoading = true; })
            },
            'hide-loading' () {
                Vue.nextTick( () => {  this.isLoading = false; })
            }
        }
    }
</script>