<template>
    <div class="filter-bar">
      <form class="form-inline">
        <div class="form-group">
          <label>Ricerca per:</label>
          <input type="text" v-model="number" class="form-control" @keyup.enter="doFilter" placeholder="num pratica" />
          <input type="text" v-model="opReference" class="form-control" @keyup.enter="doFilter" placeholder="# ordine" />
          <select ref="subscriptions" class="form-control" v-model="subscription" >
            <option value="" selected disabled >abbonamento</option>
            <option v-for="sub in subscriptionList" :value="sub.id" :key="sub.id" >{{ sub.acronym + " " + sub.name }}</option>
          </select> 
          <input type="text" v-model="licence" class="form-control" @keyup.enter="doFilter" placeholder="targa">
          <select ref="dossierState" class="form-control" v-model="dossierState" :style="{color: Constants.dossierStatus[dossierState].color}">
            <!-- <option value="" >stato</option> -->
            <!-- <option v-for="state in Constants.dossierStatusList" :value="state.id" :key="state.id" :style="{color: state.color}" :selected="state.id=='in_lavorazione'" >{{ state.name }}</option> -->
            <option v-for="state in dossierStatusList" :value="state.id" :key="state.id" :style="{color: state.color}" >{{ state.name }}</option>
          </select>
          <select ref="year" class="form-control" v-model="year" >
            <option value="" selected disabled>anno</option>
            <option v-for="y in yearList" :value="y" :key="y" >{{ y }}</option>
          </select>
          <select ref="month" class="form-control" v-model="month" >
            <option value="">mese</option>
            <option v-for="y in monthList" :value="y.id" :key="y.id" >{{ y.name }}</option>
          </select>     
          <button class="btn btn-primary" @click.prevent="doFilter(false)">Go</button>
          <button class="btn" @click.prevent="resetFilter">Reset</button>
        </div>
      </form>
    </div>
</template>

<script>
  import Constants from './Constants.vue'
  import axios from 'axios'

  export default {
    data () {
      return {
        Constants,
        muvinws_base_url: Constants.muvinws_base_url,
        number: '',
        subscription: '',
        opReference: '',
        dossierState: Constants.dossierStateDefault,
        dossierStatusList: Constants.dossierStatusList,
        licence: '',
        filter: '',
        subscriptionList: null,

        city_id: 0,
        currentYear: '',
        year: '',
        yearList: [],

        currentMonth: '',
        month: '',
        monthList: []
      }
    },
    created: function () {
      this.currentYear = new Date().getFullYear()
      this.currentMonth = new Date().getMonth() + 1
      this.fillYearList()
      this.fillMonthList()

      this.dossierStatusList = Constants.dossierStatusList

      console.log('userlLevel ' + Constants.userLevel)

      if (Constants.writePermission == 0 && Constants.userLevel != 'finalizzatore') {
        this.dossierStatusList = [ Constants.dossierStatus.in_attesa, Constants.dossierStatus.in_lavorazione, Constants.dossierStatus.da_pagare, Constants.dossierStatus.pagata, Constants.dossierStatus.pronta, Constants.dossierStatus.consegnata, Constants.dossierStatus.rifiutata ]
        this.dossierState = Constants.dossierStatus.in_attesa.id
      } else if ( Constants.userLevel == 'finalizzatore') {
        this.dossierStatusList = [ Constants.dossierStatus.pagata, Constants.dossierStatus.pronta, Constants.dossierStatus.consegnata ]
        this.dossierState = Constants.dossierStatus.pagata.id
      }

    },
    methods: {
      addFilter(filed, value) {
        if (value !== '') { 
          if (this.filter !== '')
            this.filter += ","
          this.filter += filed + "|" + value
        }
      },
      doFilter (isdefault) {
        this.filter = ''
        if (this.number !== '') {
          this.filter = "number|"+ this.number
        }
        this.addFilter("dossiers.op_reference", this.opReference)

        this.addFilter("dossiers.subscription_id", this.subscription)

        this.addFilter("state", this.dossierState)
      
        this.addFilter("licences", this.licence)
        
        this.addFilter("year", this.year)
        
        if (this.month !== '') {
          this.addFilter("month", this.month)
        }

        //console.log(this.filter)
        if (this.filter !== '')
          this.$events.fire('filter-set', this.filter, isdefault)
      },
      resetFilter () {
        // this.number = ''
        // this.subscription = ''
        // this.dossierState = Constants.dossierStateDefault
        // this.filter = ''
        // this.$events.fire('filter-reset')
        this.defaultFilter ()  
      },
      defaultFilter () {
        this.number = ''
        this.subscription = ''
        //this.subscriptionList = null
        this.dossierState = Constants.dossierStateDefault
        
        if (Constants.userLevel == 'ospite') {
          this.dossierState = Constants.dossierStatus.in_lavorazione.id
        } else if (Constants.userLevel == 'finalizzatore') {
          this.dossierState = Constants.dossierStatus.pagata.id
        }

        this.licence = ''
        this.fillSubscriptionList()
        this.year = this.currentYear
        this.month = this.currentMonth

        this.doFilter(true)
      },

      fillYearList() {
        for (let i=this.currentYear-5; i<=this.currentYear; i++) {
          // console.log("anno ", i)
          this.yearList = this.yearList.concat(i);
        }
      },

      fillMonthList() {
        this.monthList = [
          { id: '1',  name: 'gennaio'},
          { id: '2',  name: 'febbraio'},
          { id: '3',  name: 'marzo'},
          { id: '4',  name: 'aprile'},
          { id: '5',  name: 'maggio'},
          { id: '6',  name: 'giugno'},
          { id: '7',  name: 'luglio'},
          { id: '8',  name: 'agosto'},
          { id: '9',  name: 'settembre'},
          { id: '10',  name: 'ottobre'},
          { id: '11',  name: 'novembre'},
          { id: '12',  name: 'dicembre'}
        ]
      },

      fillSubscriptionList() {
        console.log('city_id', Constants.city_id)

        if (this.city_id != Constants.city_id) {
          axios
            .get(this.muvinws_base_url + '/subscriptionscity/IT/' + Constants.city_id)
            .then(res => {
              this.city_id = Constants.city_id
              this.subscriptionList = res.data;
            })
        }
      }
    },
    events: {
      'filterbar-default' () {
        this.defaultFilter()
      }
    }
  }
</script>
<style>
.filter-bar {
  padding: 10px;
}
</style>
