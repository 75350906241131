<template>
  <div class="custom-state">
    <span :style="{color: Constants.dossierStatus[rowData.state].color}">{{ Constants.dossierStatus[rowData.state].name }} </span>
  </div>
</template>

<script>
  import Constants from './Constants.vue'

  export default {
     props: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return { 
        Constants, 
      }
    },
    methods: {
    }
  }
</script>