<template>

  <div @click="onClick" ref="detailrow">
    <form class="form-inline">
      <div class="form-group">
        <label style="margin-left: 10px;">telefono: </label>
        <span style="margin-left: 5px;">{{rowData.phone}}</span>
        <label style="margin-left: 10px;">CF: </label>
        <span style="margin-left: 5px;">{{rowData.fiscalcode}}</span>
        <label style="margin-left: 10px;">PIVA: </label>
        <span style="margin-left: 5px;">{{rowData.VAT_number}}</span>
        <label style="margin-left: 10px;">email: </label>
        <span style="margin-left: 5px;">{{rowData.email}}</span>
        <label style="margin-left: 10px;">durata: </label>
        <span style="margin-left: 5px;">{{rowData.durate}}</span>

        <div class="form-group" v-if="rowData.city_id==24">
          <label style="margin-left: 10px;">num pass: </label>
          <span style="margin-left: 5px;">{{rowData.num_pass}}</span>
          <label style="margin-left: 10px;">num pass dipendenti: </label>
          <span style="margin-left: 5px;">{{rowData.num_pass_employees}}</span>
          <label style="margin-left: 10px;">num pass clienti: </label>
          <span style="margin-left: 5px;">{{rowData.num_pass_customers}}</span>
        </div>
        <div class="form-group" v-if="rowData.city_id==37">
          <label style="margin-left: 10px;">data inizio: </label>
          <span style="margin-left: 5px;">{{ formatDate(rowData.start_date) }}</span>
          <label style="margin-left: 10px;">data fine: </label>
          <span style="margin-left: 5px;">{{ formatDate(rowData.end_date) }}</span>
          <label style="margin-left: 10px;">badge: </label>
          <span style="margin-left: 5px;">{{rowData.badge}}</span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    props: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    methods: {
      onClick (event) {
        console.log('my-detail-row: on-click', event.target)
      },
      formatDate (value, fmt = 'DD-MM-YYYY') {
      return (value == null)
        ? ''
        : moment(value, 'YYYY-MM-DD').format(fmt)
      },
    },
  }
</script>
