<template>
  <span>constant</span>
</template>

<script>
  const dossierStatus = {
    errore:         { name: 'ERRORE',         id: 'errore',           color: 'red'},
    in_attesa:      { name: 'IN ATTESA',      id: 'in_attesa',        color: 'orange'},
    in_lavorazione: { name: 'IN LAVORAZIONE', id: 'in_lavorazione',   color: 'yellowgreen'},
    da_pagare:      { name: 'DA PAGARE',      id: 'da_pagare',        color: 'yellowgreen'},
    pagata:         { name: 'PAGATA',         id: 'pagata',           color: 'yellowgreen'},
    pronta:         { name: 'PRONTA',         id: 'pronta',           color: 'green' },
    consegnata:     { name: 'CONSEGNATA',     id: 'consegnata',       color: 'green' },
    incompleta:     { name: 'INCOMPLETA',     id: 'incompleta',       color: 'orangered'},
    rifiutata:      { name: 'RIFIUTATA',      id: 'rifiutata',        color: 'grey'},
    rimborsata:     { name: 'RIMBORSATA',     id: 'rimborsata',       color: 'grey'}
  }

  const dossierStatusList = [ dossierStatus.errore, dossierStatus.in_attesa, dossierStatus.in_lavorazione, dossierStatus.da_pagare, dossierStatus.pagata, dossierStatus.pronta,  dossierStatus.consegnata, dossierStatus.incompleta, dossierStatus.rifiutata, dossierStatus.rimborsata] 

  export default {

    user_id: 0,
    userLevel: null,
    username: '',
    writePermission: 0,
    city_id: 0,
    set_validity_date: 0,
    dossierStatus,
    dossierStatusList,
    dossierStateDefault: 'in_attesa',
    muvinws_base_url: 'https://muvinws.sis.city/api',
    //muvinws_base_url: 'http://muvin.test/api',

    methods: {
        // getDossierState: function (id) {
        //   console.log('stato da ricercare ' + id)
        //   dossierStatus.forEach((index) => {
        //     let state = dossierStatus[index]
        //     console.log('stato ricerca ' + state.name)
        //     if (state.id == id) {
        //       console.log('stato ottenuto ' + state.name)
        //       return state
        //     }
        //   })
        //   return null 
        // }
    }
  }
</script>

<style>
  
</style>
