<template>
    <div id="login">
         <b-card style="max-width: 40rem;" title="muvin BackOffice" class="mt-5">
            <b-form>
                <b-form-input
                    id="input-1"
                    v-model="input.username"
                    placeholder="Username"
                ></b-form-input>
                <b-form-input
                    id="input-2"
                    v-model="input.password"
                    placeholder="Password"
                    class="mt-3"
                    type="password"
                    @keyup.enter.native="login()"
                ></b-form-input>
                <div class="mt-2">
                    <b-button role="button" variant="primary" @click="login()">Login</b-button>
                    <b-button variant="danger" @click="reset" class="ml-2">Reset</b-button>
                </div>
            </b-form>
        </b-card>
    </div>
</template>

<script>
    import axios from 'axios'
    import Vue from 'vue'
    import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

    import 'bootstrap/dist/css/bootstrap.css'
    import 'bootstrap-vue/dist/bootstrap-vue.css'

    Vue.use(BootstrapVue)
    Vue.use(BootstrapVueIcons)

    import Constants from '../components/Constants.vue'

    
    export default {
        name: 'Login',
        data() {
            return {
                Constants, 
                muvinws_base_url: Constants.muvinws_base_url,
                input: {
                    username: "",
                    password: ""
                }
            }
        },
        methods: {
            reset() {
                this.input.username = ""; 
                this.input.password = "";
            },
            login() {
                // if(this.input.username != "" && this.input.password != "") {
                //     if(this.input.username == this.$parent.mockAccount.username && this.input.password == this.$parent.mockAccount.password) {
                //         this.$emit("authenticated", true);
                //         this.$router.replace({ name: "dossiersDataTable" });
                //     } else {
                //         console.log("Username o password non valida");
                //     }
                // } else {
                //     console.log("Inserire username o password");
                // }
                axios({
                    url: this.muvinws_base_url + '/login',
                    method: 'POST',
                    data: {
                        username: this.input.username,
                        password: this.input.password
                    }
                }).then((response) => {
                    if (response.data.errors) {  
                        alert('Username o password non valida.');
                    } else {
                        this.$emit("authenticated", true);
                        this.$emit("citiesList", response.data.cities);
                        Constants.user_id = response.data.id;
                        Constants.username = this.input.username + " - "

                        Constants.writePermission = response.data.write;
                        Constants.userLevel = response.data.level;
                        console.log('User id', Constants.user_id)
                        console.log('Permessi di scrittura', Constants.writePermission);
                        
                        this.$router.replace({ name: "dossiersDataTable" });
                    }
                    
                });
                
            }
        }
    }
</script>

<style scoped>
    #login {
        width: 500px;
        border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        margin: auto;
        margin-top: 200px;
        padding: 20px;
    }
</style>